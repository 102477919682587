<script lang="ts" setup>
import MobileBottom from '@/components/Mobile/MobileBottom.vue'
import MobileMenuBox from '@/components/Mobile/MobileMenuBox.vue'
import { usePageStore, useOptionStore } from '@/stores'

const optionStore = useOptionStore()
// 載入首頁資料，但不重刷
const pageStore = usePageStore()
if (!pageStore.getIsLoadData) {
  await pageStore.fetchGetHomepage()
}
onMounted(() => {
  nextTick(() => {
    optionStore.fetchAddress()
  })
})
</script>

<template>
  <div class="outerWrap">
    <main class="main">
      <slot name="content" />
    </main>
    <MobileMenuBox />
    <MobileBottom />
  </div>
</template>

<style lang="scss" scoped>
.outerWrap {
  @include max-media(990) {
    padding-bottom: toRem(50);
  }
}
</style>
